// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import App from 'next/app';
import { ThemeProvider } from 'emotion-theming';
import { StateProvider } from '../store/store';
import theme from '../theme';

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props;

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props;
    const modifiedPageProps = { ...pageProps, err };

    // TODO: Add BlinkMacSystemFont to fonts when chrome bug resolved
    // font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”,
    // “Ubuntu”, “Cantarell”, “Fira Sans”, “Helvetica Neue”, sans-serif;

    return (
      <>
        <ThemeProvider theme={theme}>
          <StateProvider>
            <Component {...modifiedPageProps} />
          </StateProvider>
        </ThemeProvider>

        <style jsx global>
          {`
            @font-face {
              font-family: 'Roboto';
              src: url('/fonts/Roboto/Roboto-Regular.ttf');
            }
            @font-face {
              font-family: 'OpenSans';
              src: url('/fonts/Open_Sans/OpenSans-Regular.ttf');
            }
            @font-face {
              font-family: 'Lato';
              src: url('/fonts/Lato/Lato-Regular.ttf');
            }

            @media screen and (min-width: 960px) {
              html {
                margin-left: calc(100vw - 100%);
                margin-right: 0;
                height: 101%;
              }
            }

            body {
              font-family: -apple-system, “Segoe UI”, “Roboto”, “Oxygen”,
                “Ubuntu”, “Cantarell”, “Fira Sans”, “Helvetica Neue”, sans-serif;
              margin: 0px;
            }
          `}
        </style>
      </>
    );
  }
}

export default MyApp;
