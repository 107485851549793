const preset = {
  colors: {
    text: '#FFD138',
    background: '#fff',
    primary: '#81d742',
    secondary: '#30c',
    muted: '#f6f6f9',
    gray: '#dddddf',
    highlight: 'hsla(205, 100%, 40%, 0.125)',
  },
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 500,
    heading: 800,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    avatar: 48,
    full: '100%',
  },
  radii: {
    default: 7,
    circle: 99999,
    small: 4,
  },
  shadows: {
    card: '0 0 4px rgba(0, 0, 0, .125)',
    nav: '0px 6px 20px rgba(0,0,0,0.06)',
    button: 'rgba(50, 50, 93, 0.1) 0 2px 2px, rgba(0, 0, 0, 0.07) 0 2px 10px',
  },
  zIndices: {
    front: 101,
    back: 1,
  },
  // rebass variants
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    display: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: [5, 6, 7],
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
    link: {
      cursor: 'pointer',
    },
  },
  variants: {
    avatar: {
      width: 'avatar',
      height: 'avatar',
      borderRadius: 'circle',
    },
    card: {
      p: 2,
      bg: 'background',
      boxShadow: 'card',
      borderRadius: 'default',
    },
    link: {
      color: 'primary',
    },
    navContainer: {
      position: 'fixed',
      height: '6em',
      width: '100%',
      top: 0,
      left: 0,
      zIndex: 'front',
    },
    notificationContainer: {
      position: 'fixed',
      width: '100%',
    },
    nav: {
      fontSize: 1,
      fontWeight: 'bold',
      display: 'inline-block',
      p: 2,
      color: 'inherit',
      textDecoration: 'none',
      ':hover,:focus,.active': {
        color: 'primary',
      },
      boxShadow: 'nav',
      position: 'fixed',
      height: '4em',
      top: 0,
      width: '100%',
      zIndex: 'front',
    },
    product: {
      borderRadius: 'default',
      justifyContent: 'center',
      alignItems: 'baseline',
      p: 2,
      '&:hover': {
        boxShadow: 'card',
        transitionDuration: 100,
      },
    },
  },
  buttons: {
    primary: {
      fontSize: 2,
      fontWeight: 'bold',
      color: 'background',
      bg: 'primary',
      borderRadius: 'default',
      p: 3,
      width: '100%',
      boxShadow: 'button',
    },
    outline: {
      variant: 'buttons.primary',
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 2px',
    },
    secondary: {
      variant: 'buttons.primary',
      color: 'background',
      bg: 'secondary',
    },
    round: {
      variant: 'buttons.primary',
      bg: 'background',
      width: 'avatar',
      height: 'avatar',
      borderRadius: 'circle',
      boxShadow: 'card',
      m: 3,
      p: 0,
    },
    minimal: {
      boxShadow: 'none',
      width: 'auto',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
  },
};

export default preset;
